import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import BannerHero from 'components/Sections/Privacy/BannerHero';
import PrivacyDelivery from 'components/Sections/Privacy/PrivacyDelivery';
import PrivacyForAll from 'components/Sections/Privacy/PrivacyForAll';
import PrivacyNews from 'components/Sections/Privacy/PrivacyNews';
import usePrivacy from 'data/pages/privacyPage/usePrivacy';

import imageBanner from '../assets/images/privacy/banner.png';
import imageBannerMobile from '../assets/images/privacy/banner-mobile.png';

const IndexPage = () => {
  const { isMobileDevice } = useWindowSize();
  const { page } = usePrivacy();

  const { 
    ourDelivery, 
    toOtherSolutionsOurDelivery,
    privacyNewsFixed,
    privacyNewsCarousel,
  } = page;

  const imgBanner = {
    src: isMobileDevice ? imageBannerMobile : imageBanner,
    alt: 'Imagem Banner Ifood'
  };

  return (
    <Layout>
      <SEO
        description="Privacidade Ifood Institucional"
        title="Privacidade"
      />
      <BannerHero 
        detail="Privacidade"
        title="Seus dados são seus."
        text="Criamos este espaço para dar transparência ao nosso compromisso com a sua privacidade."
        img={imgBanner}
      />
      <PrivacyDelivery />
      <PrivacyForAll data={ourDelivery} otherSolutions={toOtherSolutionsOurDelivery} />
      <PrivacyNews dataFixed={privacyNewsFixed} data={privacyNewsCarousel} />
    </Layout>
  )
}

export default IndexPage