import React, { useState } from 'react';
import './styles.scss';

import CardMoreText from 'components/CardMoreText';
import CardSwiper from 'components/CardSwiper';
import CardTabs from 'components/CardTabs';

import BannerImage11 from 'assets/images/privacy/banners/banner-1-1.png';
import BannerImage12 from 'assets/images/privacy/banners/banner-1-2.png';
import BannerImage21 from 'assets/images/privacy/banners/banner-2-1.png';
import BannerImage22 from 'assets/images/privacy/banners/banner-2-2.png';
import BannerImage31 from 'assets/images/privacy/banners/banner-3-1.png';
import BannerImage32 from 'assets/images/privacy/banners/banner-3-2.png';
import BannerImage41 from 'assets/images/privacy/banners/banner-4-1.png';
import BannerImage42 from 'assets/images/privacy/banners/banner-4-2.png';

const PrivacyDelivery = () => {

  const [currentSelected, setCurrentSelected] = useState(0);

  const cards = [
    {
      id: 1,
      title: 'Transparência',
      subtitle: 'Nosso compromisso é mostrar quais dados utilizamos na sua jornada.',
      image: {
        image1: {
          src: BannerImage11,
          alt: ''
        },
        image2: {
          src: BannerImage12,
          alt: ''
        },
      },
      backCard: {
        title: 'Nós levamos transparência muito a sério.',
        text: [
          'Por isso, as portas da nossa cozinha estão sempre abertas para você saber mais sobre o iFood e nosso <b>relacionamento</b> com a sociedade, os clientes, entregadores e restaurantes. Principalmente quando o assunto é o tratamento dos <b>seus dados</b>.',
          'Garantir seu acesso às nossas <b>Declarações de Privacidade</b> é parte do compromisso de ser transparente. Lá você encontra tudo que precisa saber. Não tem receita secreta: está tudo sobre a mesa. Porque manter nossa cozinha aberta garante que você entre, sinta-se confiante, fique <b>satisfeito</b> e <b>volte sempre</b>.'
        ]
      }
    },
    {
      id: 2,
      title: 'Segurança',
      subtitle: 'Adotamos medidas de proteção para manter seus dados seguros.',
      image: {
        image1: {
          src: BannerImage21,
          alt: ''
        },
        image2: {
          src: BannerImage22,
          alt: ''
        },
      },
      backCard: {
        title: 'A segurança dos seus dados é nossa prioridade.',
        text: [
          'O iFood respeita a sua privacidade e protege os seus dados com <b>base na lei</b> e nas melhores práticas do mercado. Adotamos medidas de segurança, como técnicas de <b>criptografia, monitoramento e testes de segurança periódicos</b>, para proteger os dados contra acessos não-autorizados, situações acidentais ou qualquer forma de tratamento inadequado ou ilícito. Para manter o <b>alto nível de proteção</b>, todos os dados são armazenados em um serviço de <b>nuvem</b> seguro.',
          'O momento do pagamento é cercado de cuidados bem específicos. Os <b>dados de cartão</b> são mascarados e as transações de pagamento são feitas com o mais alto padrão de segurança. Assim que o usuário cadastra as informações no aplicativo, elas se transformam em um <b>código pessoal e intransferível</b>, bem diferente dos números originais que aparecem no cartão. Esse código funciona como uma espécie de <b>identidade digital</b> dentro da plataforma iFood e toda vez que o usuário realiza a compra, a transação é feita com base nessa identidade.'
        ]
      }
    },
    {
      id: 3,
      title: 'Inovação',
      subtitle: 'Só evoluímos quando seus dados estão seguros.',
      image: {
        image1: {
          src: BannerImage31,
          alt: ''
        },
        image2: {
          src: BannerImage32,
          alt: ''
        },
      },
      backCard: {
        title: 'Nossa missão é alimentar o futuro do mundo. E isso vai muito além do delivery.',
        text: [
          'Somos uma <b>FoodTech</b>, uma empresa de tecnologia aplicada ao universo da alimentação que vai muito <b>além do delivery</b>. As possibilidades são infinitas e nos inspiram a desenvolver formas mais inteligentes de alimentar o <b>futuro do mundo</b>. Esse movimento de expansão só acontece com <b>tecnologia e inovação</b> na forma de utilizar dados. São eles que mostram tendências de mercado, buscas mais realizadas no aplicativo, perfil de compra, entre outras informações.',
          'Com a <b>coleta segura</b> de dados, e apoio de outras ferramentas, chegamos a serviços que <b>revolucionam e facilitam</b> a experiência de usuários, entregadores e parceiros do iFood, como também em outras soluções. Através de dados, entendemos a urgência de resolver <b>problemas sociais e ambientais</b> assumindo <b>compromissos</b> com o futuro. Para saber mais sobre esses projetos, dê uma olhada <a href="https://institucional.ifood.com.br/nossos-compromissos">aqui</a>.'
        ]
      }
    },
    {
      id: 4,
      title: 'Personalização',
      subtitle: 'Sua experiência é melhor quando conhecemos você.',
      image: {
        image1: {
          src: BannerImage41,
          alt: ''
        },
        image2: {
          src: BannerImage42,
          alt: ''
        },
      },
      backCard: {
        title: 'Nossa plataforma é feita para todo mundo. O que faz sua experiência única é o jeito como tratamos seus dados.',
        text: [
          'Sabe quando você vê seus restaurantes favoritos assim que acessa o iFood? Isso é apenas um exemplo de como a coleta de dados pode fazer sua <b>experiência</b> aqui dentro ser mais <b>agradável e personalizada</b>. É por isso que saber as suas <b>preferências</b> de consumo nos permite oferecer produtos ou serviços relevantes <b>para você</b>.',
          'Coletamos dados através de identificadores, como os famosos <b>cookies</b>, para ajudar a autenticar a sua conta, aperfeiçoar nossos serviços, personalizar a sua experiência, promover campanhas e avaliar a <b>eficácia</b> da nossa comunicação.'
        ]
      }
    },
  ];

  return (
    <section className="mt-80 mb-80 privacy-delivery">
      <h2 className="text-red-6 text-5xl font-bold text-center mb-60 privacy-delivery__title">
        Privacidade é parte da nossa entrega. <br />
        E de entrega a gente entende.
      </h2>
      <div className="privacy-delivery__cards">
        <CardSwiper
          id="swiper-card-more-text"
          slidesPerView={1}
          onSlideChange={({realIndex}) => setCurrentSelected(realIndex)}
          goToSelectedItem={currentSelected}
        >
          {cards.map((card, index) => (
            <CardMoreText
              title={card.title}
              subtitle={card.subtitle}
              image={card.image}
              index={index}
              key={card.id}
            />
            ))}
        </CardSwiper>
      </div>
      <div className="privacy-delivery__card-tabs">
        <div className="privacy-delivery__tabs">
          {cards.map((card, index) => (
            <CardTabs
              titleTab={card.title}
              setCurrentSelected={setCurrentSelected}
              currentSelected={currentSelected}
              index={index}
              key={card.id}
            />
          ))}
        </div>
        <div className="privacy-delivery__text-tab">
          <div className='privacy-delivery__text-tab-content'>
            <h2>{cards[currentSelected].backCard.title}</h2>
            {cards[currentSelected].backCard.text.map(text => (
              <div className="privacy-delivery__texts" key={text}>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{__html: text}} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )

}

export default PrivacyDelivery;
