import {
  ImageField,
  TitleField,
  CardsField,
  DescriptionField,
  LinkField,
} from 'plugins/content/config/fields/index'

export const ourDelivery = [
  {
    label: 'Abas',
    name: 'tabs',
    component: 'group-list',
    itemProps: (item) => ({
      key: item.id,
      label: item.title,
    }),
    fields: [
      { ...TitleField, component: 'text', label: 'Nome da aba' },
      { ...ImageField },
      { ...TitleField, component: 'text', label: 'Titulo Principal' },
      { ...DescriptionField },
      {
      ...CardsField,
        fields: [
          { ...TitleField, component: 'text', label: 'Titulo do card' },
          { ...DescriptionField, component: 'html' },
        ]
      }
    ],
  },
]

export const toOtherSolutionsOurDelivery = [
  {
    label: 'Abas - Outras soluções',
    name: 'tabs',
    component: 'group-list',
    itemProps: (item) => ({
      key: item.id,
      label: item.title,
    }),
    fields: [
      { ...TitleField, component: 'text', label: 'Nome da aba' },
      { ...TitleField, component: 'text', label: 'Titulo Principal' },
      { ...DescriptionField, name: 'bottomDescriptionFirst', component: 'html', label: 'Texto Esquerdo' },
      { ...DescriptionField, name: 'bottomDescriptionSecond', component: 'html', label: 'Texto Direito' },
    ],
  },
]

export const privacyNewsFixed = [
  { ...ImageField },
  { ...TitleField, component: 'text' },
  { ...DescriptionField },
  { ...LinkField },
]

export const privacyNewsCarousel = [
  {
  ...CardsField,
    fields: [
      { ...ImageField },
      { ...TitleField, component: 'text', label: 'Titulo do card' },
      { ...DescriptionField },
      { ...LinkField },
    ]
  }
]
