import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import Arrow from 'assets/svg/arrow-privacy.svg';
import useWindowSize from 'hooks/useWindowSize';

const CardTabs = ({
  titleTab,
  currentSelected,
  setCurrentSelected,
  index,
}) => {

  const isCurrentSelected = index === currentSelected;
  const { isMobileDevice } = useWindowSize();

  return (
    <button 
      className="card-tabs" 
      style={isCurrentSelected ? {backgroundColor: 'white', color: '#EA1D2C'} : undefined}
      onClick={() => setCurrentSelected(isCurrentSelected ? currentSelected : index)}
    >
      <h2 className="card-tabs__title">{titleTab}</h2>

      {!isMobileDevice ? <Arrow /> : null}
    </button>
  )
}

CardTabs.propTypes = {
  titleTab: PropTypes.string.isRequired,
  currentSelected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  setCurrentSelected: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

CardTabs.defaultProps = {
  currentSelected: null,
};

export default CardTabs