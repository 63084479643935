// eslint-disable

export default [
  {
    ourDelivery: {
      tabs: [
        {
          title: 'Para quem compra',
          description:
            'Seus dados pertencem a você. Tratamos o necessário para melhorar a sua experiência.',
          cards: [
            {
              title: 'Criou uma conta no iFood: e agora, o que acontece com seus dados?',
              description:
                'A partir desse momento, o iFood\né responsável pelo tratamento dos\nseus dados e faz isso com alto grau\nde segurança, de acordo com a lei e com as melhores práticas do mercado.',
            },
            {
              title: 'Quais são seus direitos sobre os dados coletados e como eles são tratados?',
              description:
                '<p>Na <a href="https://institucional.ifood.com.br/abrindo-a-cozinha/declaracao-privacidade-clientes/">Declaração de Privacidade para Usuários</a>, você encontra informações sobre quais e como seus dados são tratados, bem como conhece seus direitos garantidos por lei.</p>',
            },
            {
              title: 'Por que você fornece dados para o iFood?',
              description:
                'Porque eles são necessários para que a gente possa prover, manter, melhorar e personalizar nossos produtos e serviços destinados a você. Eles tornam possível uma experiência mais agradável e personalizada em nossas plataformas.',
            }
          ],
          image: {
            alt: 'Para quem compra',
            src: '/assets/7f0cc9d83c80177c28536b1c58d1e13f_cms-1651524509265-para-quem-comprapng.png',
          },
        },
        {
          title: 'Para quem entrega',
          description:
            'Durante a sua jornada, desde o cadastro até o repasse das entregas, seus dados são tratados com respeito à sua privacidade.',
          cards: [
            {
              title: 'Acessou o iFood e se cadastrou. E agora? O que acontece com seus dados?',
              description:
                'Seus dados são mantidos em nossa base segura de entregadores para que possamos fornecer todo o suporte durante as entregas.',
            },
            {
              title: 'Quais são seus direitos sobre os dados coletados e como eles são tratados?',
              description:
                '<p>Na <a href="https://institucional.ifood.com.br/abrindo-a-cozinha/declaracao-privacidade-entregadores/">Declaração de Privacidade\npara Entregadores</a>, você encontra informações sobre quais e como seus dados são tratados, bem como conhece seus direitos garantidos por lei.</p>',
            },
            {
              title: 'Por que você fornece dados para o iFood?',
              description:
                'Porque eles são necessários para que a gente possa conectar você a estabelecimentos e usuários, enviar avisos e notificações importantes, como comunicados sobre entregas\nde pedidos. Através dos dados também podemos oferecer suporte, realizar o seu repasse e verificar sua identidade.',
            }
          ],
          image: {
            src: '/assets/37565b79e6d2d78fdc89dc7980cd8607_cms-1651525324252-para-quem-entregapng.png',
            alt: 'Para quem entrega',
          },
        },
        {
          title: 'Para quem vende',
          image: {
            src: '/assets/e0b1d2e86f9ae13024bf51ca1e26abcb_cms-1651525361611-para-quem-vendepng.png',
            alt: 'Para quem vende',
          },
          description:
            'Oferecer uma plataforma segura e descomplicada para vender mais faz parte da nossa missão.',
          cards: [
            {
              title: 'Começou a vender no iFood. E agora? O que acontece com seus dados?',
              description:
                '<p>Pode cuidar das suas vendas que a conexão entre o estabelecimento, o cliente e o entregador é feita pelo iFood, por meio de sua tecnologia. Os dados que coletamos são armazenados em serviços de nuvem confiáveis e as medidas de segurança que implementamos estão mais robustas a cada dia, sempre para manter os seus dados protegidos.</p>',
            },
            {
              title: 'Quais são seus direitos sobre os dados coletados e como eles são tratados?',
              description:
                '<p>Coletamos somente dados necessários para a nossa uma parceria funcionar de forma segura para todos os lados. Na <a href="https://parceiros.ifood.com.br/restaurante/privacidade">Declaração de Privacidade para Parceiros</a>, você encontra informações sobre como os dados dos estabelecimentos e de seus responsáveis legais são tratados, e entende como tirar dúvidas e solicitar ajuda.</p>',
            },
            {
              title: 'Por que você fornece dados para o iFood?',
              description:
                'Os dados do estabelecimento são importantes para que a gente possa aprimorar nossos mecanismos de segurança, evitar fraudes, melhorar os serviços e oferecer suporte.\nJá os dados do responsável legal do estabelecimento são necessários para verificação de cadastro e para mantermos contato com o parceiro. ',
            }
          ],
        },
        {
          title: 'Para outras soluções',
          description:
            'Um olhar único de privacidade aplicado a outros produtos\ndo iFood além do app.',
          image: {
            src: '/assets/e146ff0be4be801232a260f6fbdedb32_cms-1651525396432-para-outras-solucoespng.png',
            alt: 'Para outras soluções',
          },
        },
      ],
    },
    privacyNewsFixed: {
      title: 'Como o iFood equilibra inovação e ética em seus modelos de IA',
      description:
        'Neste artigo, Camila Nagano e Rafaela Câmara, especialistas no tema no iFood, revelam os 3 pilares do uso responsável de IA',
      href: 'https://news.ifood.com.br/ifood-etica-ia/',
      image: {
        src: '/assets/fae90a1c-df15-42ab-96e8-f6725ec75676-modeloIa.png',
        alt: 'Como o iFood equilibra inovação e ética em seus modelos de IA',
      },
    },
    privacyNewsCarousel: {
      cards: [
        {
          title: 'O que é criptografia e como ela atua na proteção de dados',
          description:
            'Saiba como funciona essa tática de embaralhar as informações para que não possam ser lidas pelos outros.',
          href: 'https://news.ifood.com.br/o-que-e-criptografia-e-como-ela-atua-na-protecao-de-dados/',
          image: {
            src: '/assets/3e0533f2ba39fc725b22289eadf533ce_cms-1651525541155-o-que-e-criptografia-e-como-ela-atua-na-protecao-de-dadospng.png',
            alt: 'O que é criptografia e como ela atua na proteção de dados',
          },
        },
        {
          description:
            'Quem nunca cadastrou o cartão de crédito ou outras formas de pagamento no iFood e se perguntou se era seguro?',
          title: 'Formas de pagamento do iFood: saiba quais são e como são protegidas',
          href: 'https://news.ifood.com.br/formas-de-pagamento-iFood/',
          image: {
            src: '/assets/ca9402c019b71a3da8b2fed9554f284e_cms-1651526122947-formas-de-pagamento-do-ifoodpng.png',
            alt: 'Formas de pagamento do iFood: saiba quais são e como são protegidas',
          },
        },
        {
          href: 'https://news.ifood.com.br/como-o-ifood-protege-os-dados-do-seu-cartao-de-credito/',
          title: 'Como o iFood protege os dados do seu cartão',
          description:
            'Conheça as medidas de segurança adotadas para anonimizar as informações dos clientes e proteger os\ndados',
          image: {
            src: '/assets/037eebfbd611ebc4317df99dd6fd7559_cms-1651526164377-como-o-ifood-protege-os-dados-do-seu-cartaopng.png',
            alt: 'Como o iFood protege os dados do seu cartão',
          },
        },
        {
          title: 'Proteção de dados: 7 dicas para manter a segurança na internet',
          description:
            'Saiba como você pode reforçar a privacidade das suas informações ao fazer compras online.',
          image: {
            src: '/assets/12aec0099e47afc5f7934a045e685d74_cms-1651526201653-protecao-de-dadospng.png',
            alt: 'Proteção de dados: 7 dicas para manter a segurança na internet',
          },
          href: 'https://news.ifood.com.br/protecao-de-dados-7-dicas-para-manter-a-seguranca-na-internet/',
        },
        {
          image: {
            src: '/assets/84e4752c0c7bd46538ffbd755a04f9ca_cms-1651526244971-meios-de-pagamento-onlinepng.png',
            alt: 'Meios de pagamento online e como eles funcionam no iFood',
          },
          title: 'Meios de pagamento online e como eles funcionam no iFood',
          href: 'https://news.ifood.com.br/meios-de-pagamento-online-e-como-eles-funcionam-no-ifood/',
          description:
            'Nesse artigo faço a introdução de um assunto que não costumamos nos atentar muito no dia a dia: por onde nosso dinheiro caminha.',
        },
        {
          title: 'Segurança e proteção de dados pessoais no aplicativo do iFood',
          href: 'https://news.ifood.com.br/seguranca-e-protecao-de-dados-no-ifood/',
          image: {
            src: '/assets/3456a09a9e40acc34fddb4a7f9dda5dd_cms-1651526071670-seguranca-e-protecao-de-dados-pessoaispng.png',
            alt: 'Segurança e proteção de dados pessoais no aplicativo do iFood',
          },
          description:
            'Incidente de troca de nomes de restaurantes não afetou dados pessoais de usuários e não há indícios de vazamento ou qualquer tipo de acesso indevido a essa base de dados.',
        },
        {
          title: 'Comunicado Oficial: troca de nomes de estabelecimentos no app',
          href: 'https://news.ifood.com.br/comunicado-oficial-troca-de-nomes-de-estabelecimentos-no-app/',
          image: {
            src: '/assets/869e27dbb8611d32f32c62a2d447c2e4_cms-1651526358374-default-imagepng.png',
            alt: 'Comunicado Oficial: troca de nomes de estabelecimentos no app',
          },
          description: '',
        },
        {
          href: 'https://institucional.ifood.com.br/sala-de-imprensa/nota-esclarecimento-19-06',
          title: 'Nota de Esclarecimento 19 de junho',
          image: {
            src: '/assets/1539aee0a9269e38219e111dc1f17d3b_cms-1651526388209-default-imagepng.png',
            alt: 'Nota de Esclarecimento 19 de junho',
          },
          description: '',
        },
      ],
    },
    toOtherSolutionsOurDelivery: {
      tabs: [
        {
          title: 'iFood Institucional',
          bottomDescriptionFirst:
            '<p>O iFood Institucional tem como finalidade informar você sobre nossas declarações de privacidade específicas para diferentes soluções do iFood e titulares envolvidos, carreiras, iniciativas para entregadores, soluções sustentáveis, manifestações na mídia e demais medidas de transparência. Todo material está disponível sem necessidade de cadastro.</p>',
          bottomDescriptionSecond:
            '<p>A Declaração de Privacidade do iFood Institucional tem como objetivo dar transparência ao nosso compromisso com a sua privacidade, segurança da informação e proteção dos dados pessoais dos usuários que acessam esse site.</p>\n<p>Para saber mais sobre quais dados são coletados, as finalidades de tratamento e seus direitos garantidos por lei, acesse a <a href="https://institucional.ifood.com.br/wp-content/uploads/2024/03/Declaracao-de-Privacidade-iFood-Institucional-vfinal.pdf">Declaração de Privacidade iFood Institucional</a>.</p>',
        },
        {
          title: 'iFood Pago',
          bottomDescriptionFirst:
            '<p><strong>Por que você fornece seus dados ao iFood Pago?</strong></p>\n<p>Conhecendo você melohr, podemos oferecer serviços, promoções, suporte, enviar comunicados e gerenciar pagamentos, garantindo a segurança dos seus dados na plataforma.</p>',
          bottomDescriptionSecond:
            '<p><strong>Quais dados são coletados e como são usados?</strong></p>\n<p>Na <a href="https://movilepay-webviews.ifood.com.br/account/privacy-policy/index.html">Declaração de Privacidade para iFood Pago</a> você encontra os detalhes sobre os dados coletados, entende como e por que são tratados e conhece seus direitos garantidos por lei.</p>',
        },
        {
          title: 'iFood Benefícios',
          bottomDescriptionFirst:
            '<p><strong>Por que você fornece seus dados ao se cadastrar no iFood Benefícios?</strong></p>\n<p>Conhecendo você melhor podemos oferecer nossos serviços, promoções, suporte, enviar comunicados, gerenciar pagamentos e garantir a segurança dos seus dados dentro da plataforma.</p>',
          bottomDescriptionSecond:
            '<p><strong>Quais dados são coletados e como são usados?</strong></p>\n<p>Na <a href="https://empresas.ifood.com.br/declaracao-de-privacidade-para-ifood-beneficios">Declaração de Privacidade iFood Benefícios</a> você encontra os detalhes sobre os dados coletados, entende como e por que são tratados e conhece seus direitos garantidos por lei.',
        },
        {
          title: 'iFood Shop',
          bottomDescriptionFirst:
            '<p><strong>Por que você fornece seus dados ao se cadastrar no iFood Shop?</strong></p>\n<p>Conhecendo você melhor, podemos oferecer nossos serviços, promoções, suporte, enviar comunicados, realizar pesquisas, gerenciar pagamentos e garantir a segurança dos seus dados dentro da plataforma.</p>',
          bottomDescriptionSecond:
            '<p><strong>Quais dados são coletados e como são usados?</strong></p>\n<p>Na <a href="https://shop.ifood.com.br/institucional/politica-de-privacidade-ifood">Declaração de Privacidade iFood Shop</a> você encontra todos os detalhes sobre os dados coletados, entende como e por que são tratados e conhece seus direitos garantidos por lei.</p>',
        },
        {
          title: 'Carreiras',
          bottomDescriptionFirst:
            '<p><strong>Por que você fornece seus dados no Portal de Carreiras?</strong></p>\n<p>Para informar você sobre nossas vagas abertas e carreiras disponíveis, além de comunicar sobre a nossa cultura, benefícios, iniciativas de diversidade e eventos futuros.</p>',
          bottomDescriptionSecond:
            '<p><strong>Quais dados são coletados e como são usados?</strong></p>\n<p>Na <a href="https://carreiras.ifood.com.br/privacy/">Declaração de Privacidade do Portal de Carreiras</a> iFood você encontra todos os detalhes sobre os dados coletados, entende como e por que são tratados e conhece seus direitos garantidos por lei.</p>',
        },
      ],
    },
    targetClass: 'PrivacyPage',
    createdAt: '2022-04-01T19:00:19.043Z',
    updatedAt: '2022-05-06T18:32:39.975Z',
    objectId: 'GNFaau1xJX',
  },
]