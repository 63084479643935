import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Button from 'components/Button';

import NavigationArrow from 'assets/svg/arrow-right-privacy.svg';

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { getUniqueId } from 'utils/helpers';

import useWindowSize from 'hooks/useWindowSize';

require('swiper/swiper.scss')
require('swiper/components/navigation/navigation.scss')
require('swiper/components/pagination/pagination.scss')

SwiperCore.use([Navigation, Pagination])

const PrivacyNews = ({ data, dataFixed }) => {
  const { isMobileDevice } = useWindowSize();
  const [newsData, setNewsData] = useState();
  const [width, setWidth] = useState();

  React.useEffect(() => {
    setWidth(window?.screen.width || window.innerWidth);
  }, [width]);

  React.useEffect(() => {
    if (data !== {}) setNewsData(data);
  }, [data]);

  return (
    <section className="mt-80 mb-80 privacy-news">
      <div className="privacy-news__container">
        <h2 className="text-5xl font-bold text-center mb-60 privacy-news__title">
          <a href="https://www.news.ifood.com.br/ultimas-noticias/">Notícias</a>
        </h2>
        {!isMobileDevice && (
          <div className="privacy-news__card">
            <img src={dataFixed?.image?.src} alt={dataFixed?.title} />
            <div className="privacy-news__content">
              <h2>{dataFixed?.title}</h2>
              <p>{dataFixed?.description}</p>
              <div className="privacy-news__btn">
                <Button
                  className="text__heading--button"
                  onClick={() => window.open(`${dataFixed?.href}`, '__blank')}
                >
                  Ler mais
                  <NavigationArrow className="privacy-news__arrow" />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="mt-40 privacy-news__carousel">
          <Swiper
            // eslint-disable-next-line no-nested-ternary
            slidesPerView={width < 768 ? 1 : width >= 768 && width < 1024 ? 2 : 3}
            spaceBetween={20}
            pagination={{
              el: '.swiper-pagination-news',
              type: 'bullets',
              clickable: true,
              renderBullet: (index, className) =>
                `<span class="swiper-pagination-news ${className}" data-index="${
                  index + 1
                }"></span>`,
            }}
            navigation={{
              nextEl: `.to-be-partner-next-action`,
              prevEl: `.to-be-partner-prev-action`,
            }}
          >
            {newsData?.cards?.map(({ title, image, description, href }) => (
              <SwiperSlide key={getUniqueId()}>
                <div className="privacy-news__card privacy-news__horizontal">
                  <div>
                    <img src={image?.src} alt={title} />
                  </div>
                  <div className="privacy-news__content">
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <div className="privacy-news__btn">
                      <Button
                        className="text__heading--button"
                        onClick={() => window.open(`${href}`, '__blank')}
                      >
                        Ler mais
                        <NavigationArrow className="privacy-news__arrow" />
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-pagination-news" />
      </div>
    </section>
  )

}

PrivacyNews.propTypes = {
  data: PropTypes.instanceOf(Object),
  dataFixed: PropTypes.instanceOf(Object),
}

PrivacyNews.defaultProps = {
  data: {},
  dataFixed: {},
}

export default PrivacyNews;
