import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const BannerHero = ({title, detail, text, img}) => {

  return (
    <section className="banner-hero">
      <div className="banner-hero__content">
        <div className="banner-hero__text-container">
          <div className="banner-hero__text-container-info">
            <span className="text-xl banner-hero__detail">{detail}</span>
            <h2 className="text-lg banner-hero__title">{title}</h2>
            <p className="text-lg banner-hero__text">{text}</p>
          </div>
        </div>
        <div className="banner-hero__images">
          <img src={img.src} alt={img.alt} className="banner-hero__img" />
        </div>
      </div>
    </section>
  )
}

BannerHero.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.instanceOf(Object),
}

BannerHero.defaultProps = {
  img: {},
}

export default BannerHero
