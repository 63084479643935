// eslint-disable-next-line
export const pages = {
  homePage: {
    type: 'HomePage',
  },
  ifoodPage: {
    type: 'IfoodPage',
  },
  communityPage: {
    type: 'CommunityPage',
  },
  careersPage: {
    type: 'CareersPage',
  },
  openKitchenPage: {
    type: 'OpenKitchenPage',
  },
  ourCommitmentsPage: {
    type: 'OurCommitmentsPage',
  },
  mediaCenterPage: {
    type: 'MediaCenterPage',
  },
  donationsPage: {
    type: 'DonationsPage',
  },
  ourDeliveryPage: {
    type: 'OurDeliveryPage',
  },
  couriersPage: {
    type: 'CouriersPage',
  },
  restaurantsPage: {
    type: 'RestaurantsPage',
  },
  privacyPage: {
    type: 'PrivacyPage',
  },
  musicFestPage: {
    type: 'MusicFestPage'
  }
}
