import { pages } from 'config/pages'
import { mergeContent } from 'plugins/content'
import useCmsContent from 'plugins/content/hooks/useCmsContent'

import {
  ourDelivery,
  privacyNewsFixed,
  privacyNewsCarousel,
  toOtherSolutionsOurDelivery,
} from 'data/pages/privacyPage/fields'

import { defaultContent } from 'data/pages/privacyPage/defaults'
import latestContent from '../../../../temp/privacyPage'

const { privacyPage } = pages;

export default function () {
  const [content] = useCmsContent({
    id: privacyPage.type,
    type: privacyPage.type,
    title: 'Conteúdo da página',
    defaults: mergeContent(defaultContent, latestContent[0]),
    fields: [
      {
        label: 'Privacidade para todos',
        description: 'Editar conteúdo da seção',
        name: 'ourDelivery',
        component: 'group',
        fields: ourDelivery,
      },
      {
        label: 'Privacidade para todos - Para outras soluções',
        description: 'Editar conteúdo da seção',
        name: 'toOtherSolutionsOurDelivery',
        component: 'group',
        fields: toOtherSolutionsOurDelivery,
      },
      {
        label: 'Privacidade - Noticia Fixa',
        description: 'Editar conteúdo da seção',
        name: 'privacyNewsFixed',
        component: 'group',
        fields: privacyNewsFixed,
      },
      {
        label: 'Privacidade - Noticias',
        description: 'Editar conteúdo da seção',
        name: 'privacyNewsCarousel',
        component: 'group',
        fields: privacyNewsCarousel,
      },
    ],
  })

  return {
    page: content,
  }
}
