import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CardMoreText = ({
  title,
  subtitle,
  image,
}) => {

  return (
    <div className="card-more-text">
      <img src={image.image1.src} alt={image.image1.alt} className="card-more-text__img card-more-text__img--1" />
      <h2 className="card-more-text__title">{title}</h2>
      <h4 className="card-more-text__subtitle">{subtitle}</h4>
      <img src={image.image2.src} alt={image.image2.alt} className="card-more-text__img card-more-text__img--2" />
    </div>
  )
}

CardMoreText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.instanceOf(Object).isRequired,
};

export default CardMoreText

