import React from 'react'
import PropTypes from 'prop-types'
import ArrowLeft from 'assets/svg/arrow-left.svg'
import ArrowRight from 'assets/svg/arrow-right.svg'

import './styles.scss'

const SwiperCustomButtons = ({ prevButtonClass, nextButtonClass, onClickLeft, onClickRight }) => (
  <>
    <button
      type="button"
      title="Previous slide"
      className={`swiper-prev ${prevButtonClass}`}
      onClick={onClickLeft}
    >
      <ArrowLeft />
    </button>
    <button
      type="button"
      title="Next slide"
      className={`swiper-next ${nextButtonClass}`}
      onClick={onClickRight}
    >
      <ArrowRight />
    </button>
  </>
)

SwiperCustomButtons.propTypes = {
  prevButtonClass: PropTypes.string.isRequired,
  nextButtonClass: PropTypes.string.isRequired,
  onClickRight: PropTypes.func,
  onClickLeft: PropTypes.func,
}

SwiperCustomButtons.defaultProps = {
  onClickRight: () => null,
  onClickLeft: () => null,
}

export default SwiperCustomButtons
