import React, { useMemo } from 'react'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import SwiperCustomButtons from 'components/SwiperCustomButtons'
import useWindowSize from 'hooks/useWindowSize'
import 'components/CardSwiper/styles.scss'

require('swiper/swiper.scss')
require('swiper/components/navigation/navigation.scss')
require('swiper/components/scrollbar/scrollbar.scss')

SwiperCore.use([Navigation, Scrollbar])

const CardSwiper = ({ id, children, showScrollbar, goToSelectedItem, ...props }) => {
  const { width: windowWidth } = useWindowSize()
  const isMobileDevice = windowWidth && windowWidth < 768
  const childrenLength = React.Children.count(children)

  const [swiper, setSwiper] = React.useState(null);

  React.useEffect(() => {
    if (swiper && goToSelectedItem !== null) {
      swiper.slideTo(goToSelectedItem);
    }
  }, [goToSelectedItem, swiper]);


  function renderButtonNavigation() {
    if ((isMobileDevice && childrenLength > 1) || childrenLength >= 2) {
      return (
        <div className="swiper-buttons">
          <SwiperCustomButtons
            nextButtonClass={`swiper-next--${id}`}
            prevButtonClass={`swiper-prev--${id}`}
          />
        </div>
      )
    }
    return null
  }

  const renderDivScrollBar = useMemo(() => {
    const isVisible =
      ((isMobileDevice && childrenLength > 1) || childrenLength >= 2) && showScrollbar

    const styles = {
      height: isVisible ? '5px' : 0,
      visibility: isVisible ? 'visible' : 'hidden',
    }
    return <div id="swiper-scrollbar" className="swiper-scrollbar" style={styles} />
  }, [isMobileDevice, childrenLength, showScrollbar])

  return (
    <>
      <Swiper
        slidesPerView={isMobileDevice ? 1 : 2}
        spaceBetween={25}
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: false,
        }}
        navigation={{
          nextEl: `.swiper-next--${id}`,
          prevEl: `.swiper-prev--${id}`,
        }}
        onSwiper={(s) => {setSwiper(s)}}
        {...props}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide key={`slide-${uuidv4()}`}>{child}</SwiperSlide>
        ))}
        {renderDivScrollBar}
      </Swiper>
      {renderButtonNavigation()}
    </>
  )
}

CardSwiper.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  showScrollbar: PropTypes.bool,
  goToSelectedItem: PropTypes.number
}
CardSwiper.defaultProps = {
  showScrollbar: false,
  goToSelectedItem: null,
}
export default CardSwiper
