import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { getUniqueId } from 'utils/helpers';

import Loader from 'components/Loader';
import withHtmlContent from 'utils/withHtmlContent';

require('swiper/swiper.scss');
require('swiper/components/navigation/navigation.scss');
require('swiper/components/pagination/pagination.scss');

SwiperCore.use([Navigation, Pagination]);

const PrivacyForAll = ({ data, otherSolutions }) => {

  const [currentTab, setCurrentTab] = useState(0);
  const [currentTabOtherSolutions, setCurrentTabOtherSolutions] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [privacyData, setPrivacyData] = useState();
  const [otherSolutionsData, setOtherSolutionsData] = useState();
  const [width, setWidth] = useState();

  React.useEffect(() => {
    setWidth(window?.screen.width || window.innerWidth);
  }, [width]);

  React.useEffect(() => {
    if (data !== {}) setPrivacyData(data.tabs);
    if (otherSolutions !== {}) setOtherSolutionsData(otherSolutions.tabs);
  }, [data, otherSolutions]);

  React.useEffect(() => {
    if (privacyData && privacyData?.length) {
      setShowLoader(true);
      setTimeout(() => {
        setCurrentIndex(currentTab);
        setShowLoader(false);
      }, 0);
    }
  }, [currentTab, privacyData]);

  return (
    <section className="mt-80 mb-80 privacy-forall">
      <h2 className="text-red-6 text-5xl font-bold text-center mb-60 privacy-forall__title">
        Tem privacidade para todos no iFood.
      </h2>
      <div className="privacy-forall__tabs">
        {privacyData?.length > 0 && privacyData.map((item, index) => (
          <button
            className="privacy-forall__tab"
            style={currentTab === index ? {backgroundColor: '#FFB761', color: '#FFF', fontWeight: '700'} : null}
            key={item.title}
            onClick={() => setCurrentTab(index)}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="privacy-forall__card">
        <div className="privacy-forall__card-container">
          {privacyData?.length && currentIndex === currentTab && !showLoader && privacyData[currentTab].image.src ? (
            <img
              src={privacyData[currentTab].image.src}
              alt={privacyData[currentTab].title}
              className="privacy-forall__img"
              width="329"
              height="250"
            />
          ):(
            <div className="privacy-forall__loader">
              <Loader />
            </div>
          )}
          <div className="privacy-forall__card-content">
            <h3 className="privacy-forall__card-title">{privacyData?.length > 0 && privacyData[currentTab].title}</h3>
            <p className="privacy-forall__card-subtitle">{privacyData?.length > 0 && privacyData[currentTab].description}</p>
          </div>
        </div>
        {privacyData?.length > 0 && privacyData[currentTab].title !== 'Para outras soluções' ? (
          <div className="privacy-forall__cards">
            <Swiper
              id="privacy-forall"
              // eslint-disable-next-line no-nested-ternary
              slidesPerView={width < 650 ? 1 : width >= 650 && width < 1024 ? 2 : 2.3}
              spaceBetween={20}
              pagination={{
                el: '.swiper-pagination-forall',
                type: 'bullets',
                clickable: true,
                renderBullet: (index, className) =>
                  `<span class="swiper-pagination-forall ${className}" data-index="${
                    index + 1
                  }"></span>`,
              }}
            >
              {privacyData?.length > 0 && privacyData[currentTab]?.cards.map(card => (
                <SwiperSlide key={getUniqueId()}>
                  <div className="privacy-forall__card-blank">
                    <h2>{card.title}</h2>
                    <p {...withHtmlContent(card.description)} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-pagination-forall" />
          </div>
        ):(
        <>
          <div className="privacy-forall__tabs">
            {otherSolutionsData?.length > 0 && otherSolutionsData.map((item, index) => (
              <button
                className="privacy-forall__tab privacy-forall__tab--dark"
                style={currentTabOtherSolutions === index ? {backgroundColor: '#FFF', color: '#B31511', fontWeight: '700'} : null}
                key={item.title}
                onClick={() => setCurrentTabOtherSolutions(index)}
              >
                {item.title}
              </button>
            ))}
          </div>

          <div className="privacy-forall__cards privacy-forall__cards--unique">
            <div className="privacy-forall__card-blank">
              <h2 className="privacy-forall-unique__title">{otherSolutionsData?.length > 0 && otherSolutionsData[currentTabOtherSolutions]?.title}</h2>
              <div className="privacy-forall__cards-grid">
                <p {...withHtmlContent(otherSolutionsData?.length > 0  && otherSolutionsData[currentTabOtherSolutions]?.bottomDescriptionFirst)} />
                <p {...withHtmlContent(otherSolutionsData?.length > 0  && otherSolutionsData[currentTabOtherSolutions]?.bottomDescriptionSecond)} />
              </div>
            </div>
          </div>
        </>
        )}
      </div>
    </section>
  )

}

PrivacyForAll.propTypes = {
  data: PropTypes.instanceOf(Object),
  otherSolutions: PropTypes.instanceOf(Object),
}

PrivacyForAll.defaultProps = {
  data: {},
  otherSolutions: {},
}

export default PrivacyForAll;
